<template>
  <div>
    <audio-player :src="audioFile" />
    <div v-if="noAudio">Geen audio beschikbaar</div>

    <rest-table
        endpoint="album-tracks"
        :filter="filter"
        :fields="fields">

      <template v-slot:cell(artistTitle)="data">
        <router-link :to="{ name: 'track-detail', params: { albumId: data.item.AlbumID, id: data.item.TrackMetadataID } }">
          <strong>{{ data.item.TrackMetadata.ProductDetails.Attribution }}</strong> - {{ data.item.TrackMetadata.ProductDetails.Title }} {{ data.item.TrackMetadata.ProductDetails.VersionTitle }}
        </router-link>
      </template>
      
      <template v-slot:cell(TrackMetadata.SingleSaleAllowed)="data">
        <span v-if="data.item.TrackMetadata.SingleSaleAllowed == '1'" style="font-weight: bold; color: green">V</span>
        <span v-if="data.item.TrackMetadata.SingleSaleAllowed == '0'" style="font-weight: bold; color: red">X</span>
      </template>

      <template v-slot:cell(TrackMetadata.AssetFile.IsPresent)="data">
        <span v-if="data.item.TrackMetadata.AssetFile.IsPresent == '1'" style="font-weight: bold; color: green">V</span>
        <span v-if="data.item.TrackMetadata.AssetFile.IsPresent == '0'" style="font-weight: bold; color: red">X</span>
      </template>

      <template v-slot:cell(actions)="data">
        <span class="text-nowrap">
          <router-link :to="{ name: 'track-detail', params: { albumId: data.item.AlbumID, id: data.item.TrackMetadataID } }">
            <b-icon-pencil-square></b-icon-pencil-square>
          </router-link>

          <b-icon-play-fill 
            v-if="data.item.TrackMetadata.AssetFile.IsPresent == 1"
            v-on:click="play(data.item)"
            class="ml-2" />

        </span>
      </template>
    </rest-table>
  </div>
</template>

<script>
  import RestTable from '@/components/RestTable.vue'
  import AudioPlayer from '@/components/AudioPlayer.vue'

  export default {
    name: 'AlbumTrackList',
    components: {
      RestTable,
      AudioPlayer,
    },
    props: {
      filter: Object
    },
    data () {
      return {
        noAudio: false,
        audioFile: '',
        fields: [
          {
            key: 'idx',
            label: '#'
          },
          {
            key: 'DiskSeq',
            label: 'Disk'
          },
          {
            key: 'TrackSeq',
            label: 'Track'
          },
          {
            key: 'TrackMetadata.ProductDetails.ReportingCode',
            label: 'Boep ID'
          },
          {
            key: 'TrackMetadata.ProductDetails.ISRC',
            label: 'ISRC'
          },
          {
            key: 'artistTitle',
            label: 'Artiest en Titel',
            sortable: true,
          },
          {
            key: 'TrackMetadata.Duration',
            label: 'Duration',
            sortable: true,
          },
          {
            key: 'TrackMetadata.SingleSaleAllowed',
            label: 'LV',
            headerTitle: 'Losse verkoop',
            sortable: true,
          },
          {
            key: 'TrackMetadata.AssetFile.IsPresent',
            label: 'A',
            headerTitle: 'Audio',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },
        ]
      }
    },
    methods: {
      play (data) {
        if (data.TrackMetadata.AssetFile.IsPresent) {
          this.noAudio = false
          this.audioFile = 'https://assets.the-source.eu/clip/' + data.TrackMetadata.ProductDetails.ReportingCode
        } else {
          this.audioFile = ''
          this.noAudio = true
        }

      }
    }
  }
</script>