var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.releaseSearch
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "release-search", query: _vm.releaseSearch }
                      }
                    },
                    [_vm._v(" Keer terug naar de playlist search... ")]
                  )
                : _vm._e(),
              _c(
                "b-card",
                { staticClass: "mt-4", attrs: { header: _vm.$t("Release") } },
                [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c("tr", [
                      _c("th", [_vm._v("Boep Identifier")]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.release.ProductDetails.ReportingCode))
                      ]),
                      _c("th", [_vm._v("Online Release Datum")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("dateFormat")(_vm.release.ReleaseDate))
                        )
                      ]),
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          attrs: { rowspan: "9", width: "250" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                "https://assets.the-source.eu/frontcover/" +
                                _vm.release.ProductDetails.ReportingCode +
                                "/600?refresh=" +
                                new Date().getTime(),
                              width: "100%"
                            }
                          }),
                          _c("p", {
                            staticClass: "mt-3",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.deliveryStatusFormatter(_vm.release)
                              )
                            }
                          }),
                          _vm.release.Security.ChangeArtwork
                            ? [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("Change artwork")) + ":"
                                    )
                                  ])
                                ]),
                                _c("blob-upload", {
                                  staticClass: "mb-4",
                                  on: {
                                    input: function($event) {
                                      _vm.artworkReplacement = $event
                                    }
                                  }
                                }),
                                _vm.artworkReplacement &&
                                _vm.artworkReplacementStatus == null
                                  ? [
                                      _vm.artworkReplacement.status ==
                                      "uploading"
                                        ? _c("b-progress", {
                                            attrs: {
                                              value:
                                                _vm.artworkReplacement
                                                  .uploadPercentage,
                                              max: 100,
                                              "show-progress": ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.artworkReplacement.status ==
                                      "uploadError"
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "An error occurred while uploading, please try again."
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm.artworkReplacement.status ==
                                      "uploadComplete"
                                        ? [
                                            _vm.artworkReplacementValid == true
                                              ? [
                                                  _c("b-img", {
                                                    attrs: {
                                                      fluid: "",
                                                      src:
                                                        _vm.artworkReplacementSrc
                                                    }
                                                  }),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-modal",
                                                          rawName:
                                                            "v-b-modal.confirm-artwork-modal",
                                                          modifiers: {
                                                            "confirm-artwork-modal": true
                                                          }
                                                        }
                                                      ],
                                                      staticClass: "mt-3",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Confirm artwork"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : _vm._e(),
                                            _vm.artworkReplacementValid != true
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Problems found with the uploaded file. Please try again."
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm._l(
                                                    _vm.artworkReplacementValid,
                                                    function(msg, index) {
                                                      return _c(
                                                        "b-alert",
                                                        {
                                                          key: index,
                                                          staticClass: "mt-4",
                                                          attrs: {
                                                            variant: "danger",
                                                            show: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(msg) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ]
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _c(
                                  "b-modal",
                                  {
                                    attrs: {
                                      id: "confirm-artwork-modal",
                                      title: _vm.$t("Confirm artwork"),
                                      "ok-title": _vm.$t("Confirm"),
                                      "cancel-title": _vm.$t("Cancel"),
                                      "ok-disabled":
                                        _vm.artworkReplacementReason.length < 5
                                    },
                                    on: { ok: _vm.replaceArtwork }
                                  },
                                  [
                                    _c("page-snippet", {
                                      attrs: { name: "artwork-replace" }
                                    }),
                                    _c("b-form-textarea", {
                                      attrs: {
                                        id: "textarea",
                                        placeholder: _vm.$t("Enter reason"),
                                        rows: "3",
                                        "max-rows": "6"
                                      },
                                      model: {
                                        value: _vm.artworkReplacementReason,
                                        callback: function($$v) {
                                          _vm.artworkReplacementReason = $$v
                                        },
                                        expression: "artworkReplacementReason"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.artworkReplacement &&
                                _vm.artworkReplacementStatus == "progress"
                                  ? [
                                      _c("b-spinner", {
                                        attrs: { label: "Spinning" }
                                      })
                                    ]
                                  : _vm._e(),
                                _vm.artworkReplacement &&
                                _vm.artworkReplacementStatus == "success"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "Artwork change has been requested. It can take a moment before this will be visible."
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.artworkReplacement &&
                                _vm.artworkReplacementStatus == "error"
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "An error occurred while uploading, please try again."
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("Catalog Number")))]),
                      _c("td", [_vm._v(_vm._s(_vm.release.CatalogNr))]),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("Original Release Date")))
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateFormat")(
                              _vm.release.OriginalReleaseDate
                            )
                          )
                        )
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("Barcode")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.release.ProductDetails.UPCorEAN))
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("Label Website")))]),
                      _c("td", [_vm._v(_vm._s(_vm.release.LabelURL))])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("Attribution")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.release.ProductDetails.Attribution))
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("Artist Website")))]),
                      _c("td", [_vm._v(_vm._s(_vm.release.ArtistURL))])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("Title")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.release.ProductDetails.Title))
                      ]),
                      _c("th", [_vm._v("Land van Release")]),
                      _c("td", [_vm._v(_vm._s(_vm.release.OriginCountry))])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("Version")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.release.ProductDetails.VersionTitle))
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("Org Releaseformat")))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.release.OriginalReleaseFormat))
                      ])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("C-Line")))]),
                      _c("td", [_vm._v(_vm._s(_vm.release.CLine))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("Track count")))]),
                      _c("td", [_vm._v(_vm._s(_vm.release.TotalTrackCount))])
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("P-Line")))]),
                      _c("td", [_vm._v(_vm._s(_vm.release.PLine))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("Release Status")))]),
                      _c("td", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.deliveryStatusFormatter(_vm.release)
                          )
                        }
                      })
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("Language")))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.release.ProductDetails.TitleLanguage.Description
                          )
                        )
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("Label")))]),
                      _c("td", [_vm._v(_vm._s(_vm.release.Label))])
                    ])
                  ])
                ]
              ),
              false
                ? _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: { header: "Transaction Matches" }
                    },
                    [_c("trx-match-list", { attrs: { release: _vm.release } })],
                    1
                  )
                : _vm._e(),
              _c(
                "b-card",
                { staticClass: "mt-4", attrs: { header: "Tracks" } },
                [
                  _c("album-track-list", {
                    attrs: { filter: { AlbumID: _vm.release.AlbumID } }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { cols: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Contributors" } },
                [
                  _c("contributor-list", {
                    attrs: { holder: "Album", holderId: _vm.release.AlbumID }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { cols: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Tags" } },
                [
                  _c("tag-list", {
                    attrs: { holder: "Album", holderId: _vm.release.AlbumID }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { cols: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Attributes" } },
                [
                  _c("attribute-list", {
                    attrs: {
                      holder: "Album",
                      productDetailsId: _vm.release.ProductDetailsID
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { cols: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("Release schedule") } },
                [
                  _c(
                    "b-table-simple",
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("Outlet")))]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("Deliveries")))])
                        ])
                      ]),
                      _vm._l(_vm.outlets, function(outlet, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "td",
                            {
                              staticClass: "text-center outlet-logo",
                              attrs: { width: "225" }
                            },
                            [
                              outlet.logoUrl
                                ? _c("img", {
                                    attrs: {
                                      src: outlet.logoUrl,
                                      alt: outlet.name,
                                      title: outlet.name
                                    }
                                  })
                                : _vm._e(),
                              !outlet.logoUrl
                                ? _c("span", [_vm._v(_vm._s(outlet.name))])
                                : _vm._e()
                            ]
                          ),
                          _c("td", [
                            _c(
                              "ul",
                              [
                                _vm._l(outlet.deliveries, function(
                                  delivery,
                                  index
                                ) {
                                  return _c(
                                    "li",
                                    { key: index },
                                    [
                                      !delivery.CompletionDateTime
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Processing")) +
                                                " "
                                            )
                                          ]
                                        : _vm._e(),
                                      delivery.CompletionDateTime
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("datetimeFormat")(
                                                    delivery.CompletionDateTime
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(delivery.Type) + " ")
                                    ],
                                    2
                                  )
                                }),
                                outlet.deliveries.length == 0 &&
                                outlet.deliverTo === true
                                  ? _c("li", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Pending")) + " "
                                      )
                                    ])
                                  : _vm._e(),
                                outlet.delivered === true &&
                                outlet.deliverTo === false
                                  ? _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Take Down Pending")) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                outlet.deliveries.length == 0 &&
                                outlet.blocked === true
                                  ? _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("Not Available")) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        ])
                      })
                    ],
                    2
                  ),
                  _vm.release.Security.PortalUpdate
                    ? _c(
                        "router-link",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            to: { name: "release-detail-outlets" },
                            tag: "b-button"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Change Outlet preferences")))]
                      )
                    : _vm._e(),
                  _vm.release.Security.PortalUpdate
                    ? [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.takedown-modal",
                                modifiers: { "takedown-modal": true }
                              }
                            ],
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Full take-down")))]
                        ),
                        _c(
                          "b-modal",
                          {
                            attrs: {
                              id: "takedown-modal",
                              title: _vm.$t("Take down this release"),
                              "ok-title": _vm.$t("Confirm"),
                              "cancel-title": _vm.$t("Cancel"),
                              "ok-disabled": _vm.fullTakedownReason.length < 5
                            },
                            on: {
                              ok: function($event) {
                                return _vm.$emit(
                                  "takedown",
                                  _vm.fullTakedownReason
                                )
                              }
                            }
                          },
                          [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$root.$data.pageSnippets["full-take-down"]
                                )
                              }
                            }),
                            _c("b-form-textarea", {
                              attrs: {
                                id: "textarea",
                                placeholder: _vm.$t("Enter reason"),
                                rows: "3",
                                "max-rows": "6"
                              },
                              model: {
                                value: _vm.fullTakedownReason,
                                callback: function($$v) {
                                  _vm.fullTakedownReason = $$v
                                },
                                expression: "fullTakedownReason"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }